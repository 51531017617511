<template>
    <div>
        <SimpleGrid
            ref="grid"
            :service="service"
            :filtrosExtras="filtrosExtras"
            :expander="expander"
        >
            <template #columns>
                <Column field="prestador.name" header="Nome prestador" />
                <Column field="status" header="Status" />
                <Column header="Ações" class="flex flex-row">
                    <template #body="slotProps">
                        <Button
                            icon="pi pi-trash"
                            class="p-button-sm mr-1"
                            label="Excluir"
                            @click="openConfirmDialog(slotProps.data)"
                        />
                        <Button
                            v-if="showAprovar"
                            icon="pi pi-check"
                            class="p-button-sm p-button-outlined"
                            label="Aprovar"
                            @click="persistirAtualizacaoPrestador(slotProps.data)"
                        />
                    </template>
                </Column>
            </template>

            <template #expansion="slotProps">
                <div>
                    <InformacoesPrestadorAtualizacao
                        :id="slotProps.data.id"
                    />
                </div>
            </template>
        </SimpleGrid>

        <AppConfirmDialog
            v-if="showConfirmDialog"
            v-model:visible="showConfirmDialog"
            :loading="false"
            title="Deseja realmente excluir a ação enviada?"
            @onClose="closeConfirmDialog"
            @onConfirm="remover"
        />
    </div>
</template>

<script>
import AppConfirmDialog from '../../../components/AppConfirmDialog.vue';
import EnumStatusAtualizacaoPrestador from '../../../enums/EnumStatusAtualizacaoPrestador';
import BaseService from '../../../services/BaseService';
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';
import { showError, showSuccess } from '../../../utils/Toast';
import SimpleGrid from '../../inventario-riscos/components/SimpleGrid.vue';
import InformacoesPrestadorAtualizacao from './InformacoesPrestadorAtualizacao.vue';

export default {
    emits: ['onAfterAprovar'],
    props: {
        expander: {
            type: Boolean
        },
        showAprovar: Boolean
    },
    components: {
        SimpleGrid,
        InformacoesPrestadorAtualizacao,
        AppConfirmDialog
    },
    data() {
        return {
            showConfirmDialog: false,
            removerData: null,
            service: null,
            serviceInformacao: null,
            filtrosExtras: {
                where: []
            },
            prestadorAtualizacaoId: 0,
        }
    },
    created() {
        this.service = new BaseService('prestador-atualizacao-prestadores');
        this.$service = new PrestadorAtualizacaoService();
    },
    methods: {
        openConfirmDialog(data) {
            this.removerData = data;
            this.showConfirmDialog = true;
        },
        closeConfirmDialog() {
            this.removerData = null;
            this.showConfirmDialog = false;
        },
        async remover() {
            this.showConfirmDialog = false;
            try {
                await this.$service.removerPrestadorAtualizacao(this.removerData?.id);
                await this.load(this.prestadorAtualizacaoId);
                showSuccess(this.$toast, 'Atualização do prestador removida!');
            } catch (error) {
                showError(this.$toast, error)
            }
        },
        async load(prestadorAtualizacaoId, somenteAguardando = false) {
            this.prestadorAtualizacaoId = prestadorAtualizacaoId;
            this.filtrosExtras.where = [];

            this.filtrosExtras.where.push({
                prop: 'prestadorAtualizacaoPrestadores.prestador_atualizacao_id',
                operator: 'equal',
                values: [this.prestadorAtualizacaoId]
            });

            if (somenteAguardando) {
                this.filtrosExtras.where.push({
                    prop: 'prestadorAtualizacaoPrestadores.status',
                    operator: 'equal',
                    values: [EnumStatusAtualizacaoPrestador.AGUARDANDO]
                });
            }

            await this.$refs.grid.load();
        },
        async persistirAtualizacaoPrestador(data) {
            try {
                await this.$service.persistirAtualizacaoPrestador({ id: data.id });
                showSuccess(this.$toast, 'Confirmada atualização');
                await this.load(this.prestadorAtualizacaoId);
                this.$emit('onAfterAprovar');
            } catch (error) {
                showError(this.$toast, error);
            }
        }
    }
}
</script>

<style>

</style>