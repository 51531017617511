<template>
    <AppMultiselect 
        v-model="value" 
        optionLabel="name" 
        dataKey="id"
        placeholder="Selecione os prestadores..." 
        :service="$service"
        @change="change">
    </AppMultiselect>
</template>

<script>
import BaseService from '../../../services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object || Array
        },
        itemSize: {
            type: Number,
            default: 20
        },
        change: {
            type: Function
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/suppliers/combo');
    }
}
</script>

<style>

</style>