<template>
    <div>
        <SimpleGrid
            ref="grid"
            :service="service"
            :filtrosExtras="filtrosExtras"
        >
            <template #columns>
                <Column field="nomeGrupo" header="Nome do grupo" />
                <Column field="dataEnvio" header="Data de envio">
                    <template #body="slotProps">
                        {{ $filters.formatDateOnly(slotProps.data?.dataEnvio) }}
                    </template>
                </Column>
                <Column field="status" header="Status" />
                <Column>
                    <template #body="slotProps">
                        <Button
                            label="Prestadores"
                            icon="pi pi-eye"
                            class="p-button-sm p-button-outlined mr-1"
                            @click="openDialog(slotProps.data)"
                        />
                        <Button
                            icon="pi pi-trash"
                            class="p-button-sm"
                            label="Excluir"
                            @click="openConfirmDialog(slotProps.data)"
                        />
                    </template>
                </Column>
            </template>
        </SimpleGrid>

        <PrestadoresEnviadosDialog
            ref="prestadoresEnviadosDialog"
            :showAprovar="false"
        />

        <AppConfirmDialog
            v-if="showConfirmDialog"
            v-model:visible="showConfirmDialog"
            :loading="false"
            title="Deseja realmente excluir a ação enviada?"
            @onClose="closeConfirmDialog"
            @onConfirm="remover"
        />
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
import SimpleGrid from '../../inventario-riscos/components/SimpleGrid.vue'
import EnumStatusAtualizacaoPrestador from '../../../enums/EnumStatusAtualizacaoPrestador';
import PrestadoresEnviadosDialog from './PrestadoresEnviadosDialog.vue';
import { showError, showSuccess } from '../../../utils/Toast';
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';
import AppConfirmDialog from '../../../components/AppConfirmDialog.vue';

export default {
    components: {
        SimpleGrid,
        PrestadoresEnviadosDialog,
        AppConfirmDialog
    },
    data() {
        return {
            showConfirmDialog: false,
            removerData: null,
            service: null,
            filtrosExtras: {
                where: [
                    {
                        prop: 'prestadorAtualizacao.status',
                        operator: 'in',
                        values: [
                            EnumStatusAtualizacaoPrestador.PENDENTE,
                            EnumStatusAtualizacaoPrestador.CONCLUIDO
                        ]
                    }
                ]
            }
        }
    },
    created() {
        this.service = new BaseService('prestador-atualizacao');
        this.$service = new PrestadorAtualizacaoService();
    },
    async mounted() {
        setTimeout(async () => {
            await this.load();
        }, 0);
    },
    methods: {
        openConfirmDialog(data) {
            this.removerData = data;
            this.showConfirmDialog = true;
        },
        closeConfirmDialog() {
            this.removerData = null;
            this.showConfirmDialog = false;
        },
        async remover() {
            this.showConfirmDialog = false;
            try {
                await this.$service.removerPrestadorAtualizacaoGrupo(this.removerData?.id);
                await this.load();
                showSuccess(this.$toast, 'Grupo removido!');
            } catch (error) {
                showError(this.$toast, error)
            }
        },
        async load() {
            await this.$refs.grid.load();
        },
        openDialog(data) {
            this.$refs.prestadoresEnviadosDialog.open(data);
        }
    }
}
</script>

<style>

</style>