import BaseService from "./BaseService";
import { getClientBase } from "./http";

export default class PrestadorAtualizacaoService extends BaseService {
    constructor() {
        super('suppliers')

        this.path = 'suppliers';
    }

    findByToken(token) {
        return getClientBase().get(`${this.path}/find-by-token/${token}`);
    }

    gerarAtualizacaoPrestador(record) {
        return getClientBase().post(`${this.path}/gerar-atualizacao-prestador`, record)
    }

    solicitarAtualizacaoPrestadores(record) {
        return getClientBase().post(`${this.path}/solicitar-atualizacao`, record)
    }

    persistirAtualizacaoPrestador(record) {
        return getClientBase().patch(`${this.path}/persistir-atualizacao-prestador/individual`, record)
    }

    persistirAtualizacaoPrestadorGrupo(record) {
        return getClientBase().patch(`${this.path}/persistir-atualizacao-prestador/grupo`, record)
    }

    buscarPrestadorAtualizacaoInformacao(id) {
        return getClientBase().get(`${this.path}/prestador-atualizacao-informacoes/prestador-atualizacao-id/${id}`);
    }

    removerPrestadorAtualizacao(id) {
        return getClientBase().delete(`${this.path}/remover-prestador-atualizaca-prestador/id/${id}`);
    }

    removerPrestadorAtualizacaoGrupo(id) {
        return getClientBase().delete(`${this.path}/remover-prestador-atualizaca-grupo/id/${id}`);
    }
}
