<template>
    <div class="">
        <TabView @tab-click="reload">
            <TabPanel header="Enviar ação">
                <AtualizarPrestadorEnviadoGrid
                    ref="atualizarPrestadorEnviadoGrid"
                />
            </TabPanel>

            <TabPanel header="Ações recebidas">
                <AtualizarPrestadorRecebidoGrid
                    ref="atualizarPrestadorRecebidoGrid"
                />
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
import AtualizarPrestadorEnviadoGrid from './AtualizarPrestadorEnviadoGrid.vue';
import AtualizarPrestadorRecebidoGrid from './AtualizarPrestadorRecebidoGrid.vue';

export default {
    components: {
        AtualizarPrestadorEnviadoGrid,
        AtualizarPrestadorRecebidoGrid
    },
    methods: {
        async reload(event) {
            if (event.index == 0) await this.loadEnviar();
            if (event.index == 1) await this.loadRecebido();
        },
        async loadEnviar() {
            await this.$refs.atualizarPrestadorEnviadoGrid.load();
        },
        async loadRecebido() {
            await this.$refs.atualizarPrestadorRecebidoGrid.load();
        }
    }
};
</script>

