<template>
    <Dialog
        :header="`Prestadores enviados para o grupo ${nomeGrupo}`"
        :visible="isOpen"
        class="p-fluid"
        :style="{ width: '80%', height: 'auto' }"
        :draggable="false"
        :closable="false"
    >
        <div v-if="showAprovar">
            <InputSwitch
                id="somenteAguardando"
                class="mr-2"
                v-model="somenteAguardando"
                @change="reload"
            />
            <label for="somenteAguardando">Mostrar somente aguardando</label>
        </div>
        <div class="flex flex-column justify-content-center p-2 mb-6">
            <PrestadoresGrid
                ref="prestadoresGrid"
                :expander="expander"
                :showAprovar="showAprovar"
                :somenteAguardando="somenteAguardando"
                @onAfterAprovar="onAfterAprovar"
            />
        </div>

        <div class="flex justify-content-end p-2 footer">
            <Button label="Fechar" @click="isOpen = false" class="w-2 mr-5 bg-primary-reverse" />
        </div>
    </Dialog>
</template>

<script>
import PrestadoresGrid from './PrestadoresGrid.vue';

export default {
    emits: ['onAfterAprovar'],
    props: {
        expander: Boolean,
        showAprovar: {
            type: Boolean,
            default: true
        }
    },
    components: {
        PrestadoresGrid
    },
    data() {
        return {
            isOpen: false,
            prestadorAtualizacaoId: 0,
            token: null,
            nomeGrupo: null,
            somenteAguardando: false
        }
    },
    methods: {
        onAfterAprovar() {
            this.$emit('onAfterAprovar');
        },
        open(data) {
            this.prestadorAtualizacaoId = data.id;
            this.isOpen = true;
            this.nomeGrupo = data.nomeGrupo;
            setTimeout(async () => {
                await this.$refs.prestadoresGrid.load(this.prestadorAtualizacaoId);
            }, 0);
        },
        async reload() {
            await this.$refs.prestadoresGrid.load(this.prestadorAtualizacaoId, this.somenteAguardando);
        }
    }
}
</script>

<style>
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
}
</style>