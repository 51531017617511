<template>
    <div>
        <SimpleGrid
            ref="grid"
            :service="serviceGrid"
            :filtrosExtras="filtrosExtras"
            :filter="filter"
        >
            <template #columns>
                <Column field="nomeGrupo" header="Nome do grupo" />
                <Column field="dataEnvio" header="Data de envio">
                    <template #body="slotProps">
                        {{ $filters.formatDateOnly(slotProps.data?.dataEnvio) }}
                    </template>
                </Column>
                <Column field="status" header="Status" />
                <Column>
                    <template #body="slotProps">
                        <Button
                            icon="pi pi-eye"
                            class="p-button-sm p-button-outlined mr-1"
                            label="Visualizar atualização prestadores"
                            @click="openDialog(slotProps.data)"
                        />
                        <Button
                            icon="pi pi-trash"
                            class="p-button-sm mr-1"
                            label="Excluir"
                            @click="remover(slotProps.data)"
                        />
                        <Button
                            icon="pi pi-check"
                            class="p-button-sm p-button-outlined"
                            label="Aprovar grupo"
                            @click="persistirAtualizacaoPrestador(slotProps.data)"
                        />
                    </template>
                </Column>
            </template>
        </SimpleGrid>

        <PrestadoresEnviadosDialog
            ref="prestadoresEnviadosDialog"
            :expander="true"
            @onAfterAprovar="onAfterAprovar"
        />
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
import SimpleGrid from '../../inventario-riscos/components/SimpleGrid.vue'
import EnumStatusAtualizacaoPrestador from '../../../enums/EnumStatusAtualizacaoPrestador';
import PrestadoresEnviadosDialog from './PrestadoresEnviadosDialog.vue';
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';
import { showError, showSuccess } from '../../../utils/Toast';

export default {
    components: {
        SimpleGrid,
        PrestadoresEnviadosDialog
    },
    data() {
        return {
            service: null,
            filter: {
                prestadoresAguardando: true
            },
            filtrosExtras: {
                where: [
                    {
                        prop: 'prestadorAtualizacao.status',
                        operator: 'in',
                        values: [
                            EnumStatusAtualizacaoPrestador.AGUARDANDO,
                            EnumStatusAtualizacaoPrestador.CONCLUIDO
                        ]
                    }
                ]
            }
        }
    },
    created() {
        this.serviceGrid = new BaseService('prestador-atualizacao');
        this.$service = new PrestadorAtualizacaoService();
    },
    async mounted() {
        setTimeout(async () => {
            await this.load();
        }, 0);
    },
    methods: {
        async onAfterAprovar() {
            await this.load();
        },
        async remover(data) {
            try {
                await this.$service.removerPrestadorAtualizacaoGrupo(data.id);
                await this.load();
                showSuccess(this.$toast, 'Grupo removido!');
            } catch (error) {
                showError(this.$toast, error)
            }
        },
        async load() {
            await this.$refs.grid.load();
        },
        openDialog(data) {
            this.$refs.prestadoresEnviadosDialog.open(data);
        },
        async persistirAtualizacaoPrestador(data) {
            try {
                await this.$service.persistirAtualizacaoPrestadorGrupo({ prestadorAtualizacaoId: data.id });
                showSuccess(this.$toast, 'Confirmada atualização');
                await this.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        }
    }
}
</script>

<style>

</style>