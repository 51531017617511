<template>
    <Dialog
        header="Adicionar ação"
        :visible="isOpen"
        class="p-fluid"
        :style="{ width: '50%', height: 'auto' }"
        :draggable="false"
        :closable="false"
    >
        <div class="flex flex-column justify-content-center p-2">
            <div class="mb-4">
                <label for="nomeGrupo">Nome do grupo</label>
                <InputText
                    id="nomeGrupo"
                    v-model="form.nomeGrupo"
                />
            </div>

            <div class="mb-4">
                <label for="prestadores">Selecionar prestadores</label>
                <MultiSelectPrestador
                    id="prestadores"
                    v-model="form.prestadores"
                />
            </div>

            <div class="mb-4">
                <label for="dataEnvio">Selecionar data de envio</label>
                <Calendar
                    id="dataEnvio"
                    v-model="form.dataEnvio"
                />
            </div>
        </div>
        <div class="flex justify-content-end p-2">
            <Button label="Cancelar" @click="isOpen = false" class="w-2 mr-2 bg-primary-reverse" />
            <Button
                :loading="loadingSave"
                label="Adicionar"
                class="w-2"
                @click="save"
            />
        </div>
    </Dialog>
</template>

<script>
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';
import { showError, showSuccess } from '../../../utils/Toast';
import MultiSelectPrestador from './MultiSelectPrestador.vue';

export default {
    emits: ['onAfterAdd'],
    components: {
        MultiSelectPrestador
    },
    mounted() {
        this.$service = new PrestadorAtualizacaoService();
    },
    data() {
        return {
            loadingSave: false,
            form: {
                prestadores: []
            },
            isOpen: false
        }
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        async save() {
            try {
                this.loadingSave = true;
                const data = {
                    nomeGrupo: this.form.nomeGrupo,
                    dataEnvio: this.form.dataEnvio,
                    prestadorIds: this.form.prestadores.map((el) => el.id)
                }
                await this.$service.solicitarAtualizacaoPrestadores(data);
                this.loadingSave = false;
                this.isOpen = false;
                this.$emit('onAfterAdd');
                showSuccess(this.$toast, 'Enviado com sucesso!');
            } catch (error) {
                showError(this.$toast, error);
                this.loadingSave = false;
            }
        }
    }
}
</script>

<style>

</style>