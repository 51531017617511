<template>
    <div class="card flex flex-column p-4">
        <div class="grid">
            <div class="field col-6">
                <Breadcrumb :home="home" :model="items" class="mt-3" style="border: 0px" />
            </div>
            <div class="field col-6 flex justify-content-end align-content-center flex-wrap">
                <Button icon="pi pi-plus" label="Adicionar ação" style="height: 60%" @click="openDialog" />
            </div>
        </div>
        <div class="ml-4 mt-2">
            <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                <AppInfoManual nomeTelaDoManual="atualizar-prestador-form" />
                <h3 class="ml-1">Atualizar cadastro</h3>
            </div>
            <span class="text-400">
                Inclua uma ação para encaminhar ao prestador uma solicitação de alteração de horário de atendimento. Os e-mail serão enviado ao final
                do dia.
            </span>
        </div>
        <Divider />

        <div class="mt-3">
            <AtualizarPrestadorTab ref="atualizarPrestadorTab" />
        </div>

        <NovoGrupoDialog ref="dialog" @onAfterAdd="onAfterAdd" />
    </div>
</template>

<script>
import { ref } from 'vue';
import AtualizarPrestadorTab from './components/AtualizarPrestadorTab.vue';
import NovoGrupoDialog from './components/NovoGrupoDialog.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
    AtualizarPrestadorTab,
    NovoGrupoDialog,
    AppInfoManual
},
    data() {
        return {
            home: ref({ icon: 'pi pi-home', to: '/', class: 'm-2' }),
            items: ref([
                { label: 'Gestão médica', to: '/atualizar-prestador', class: 'm-2' },
                { label: 'Atualizar prestador', to: '/atualizar-prestador', class: 'm-2' }
            ])
        };
    },
    methods: {
        openDialog() {
            this.$refs.dialog.open();
        },
        async onAfterAdd() {
            await this.$refs.atualizarPrestadorTab.loadEnviar();
        }
    }
};
</script>

