<template>
    <div class="flex flex-column m-3">
        <label for="textoAtualizacaoHorarios">Atualização horários</label>
        <Textarea
            class="mb-3"
            id="textoAtualizacaoHorarios"
            v-model="form.textoAtualizacaoHorarios"
            :disabled="true"
        />

        <label for="textoAtualizacaoGeral">Atulizações gerais</label>
        <Textarea
            id="textoAtualizacaoGeral"
            v-model="form.textoAtualizacaoGeral"
            :disabled="true"
        />
    </div>
</template>

<script>
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';

export default {
    props: {
        id: Number
    },
    created() {
        this.service = new PrestadorAtualizacaoService();
    },
    async mounted() {
        await this.load();
    },
    data() {
        return {
            service: null,
            form: {}
        }
    },
    methods: {
        async load() {
            const result = await this.service.buscarPrestadorAtualizacaoInformacao(this.id);
            this.form = result.data;
        }
    }
}
</script>

<style>

</style>